import React, {useState} from "react";
import {Helmet} from "react-helmet";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import Button from "../../components/button/button";
import HeaderLight from "../../components/header/headerLight/headerLight";
import InputCode from "../../components/inputCode/inputCode";

import "./style.scss";
import BlocAgences from "../../components/blocAgences/blocAgences";
import { graphql } from "gatsby";

const Location = ({ location, data }) => {
  const [code, setCode] = useState();

  return (
    <>
      <Helmet>
        <body className="location" />
      </Helmet>
      <Layout>
        <SEO title="Nos agences" description="Retrouvez notre agence la plus proche de chez vous ! Nos experts en assurance se feront un plaisir de vous accueillir et de répondre à toutes vos questions sur nos contrats en assurance à destination des professionnels du BTP."/>
        <HeaderLight
          title={
            <>
              <strong>Trouvez l'agence</strong> <br/>la plus proche de chez vous&nbsp;!
            </>
          }
          location={location}
          crumbLabel="Nos agences"
        />
        <section>
          <form className="location__search"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
          >
            <p>
              Mon code postal
            </p>
            <InputCode
              length={5}
              onComplete={c => {
                setCode(c);
              }}
            />
            <Button className="location__btn" text="Voir mon agence" agenceCode={code}/>
          </form>
        </section>
        <BlocAgences agences={data.allStrapiAgence.nodes} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query {
    allStrapiAgence {
      nodes {
        Nom
        Slug
        Image {
          localFile {
            childrenImageSharp {
              gatsbyImageData(quality: 80)
            }
          }
        }
      }
    }
  }
`;

export default Location;
