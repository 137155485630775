import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import './etreSocietaire.scss';
import BackgroundImage from 'gatsby-background-image';
import Button from '../button/button';

export default function EtreSocietaire({ etreSocietaireImg }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="etre-societaire">
      <div className="left-section">
        <div className="content-wrapper">
          {isMobile ? (
              <h2>Être proches, c’est de loin <br/> le plus important.</h2>
          ) : (
              <h2>
                Être proches, <br/>
                c’est de loin <br/>
                le plus important.
              </h2>
          )}
          <p>
            8 sociétaires sur 10 nous recommandent <br/>
            (vraiment). Découvrez pourquoi !
          </p>
          <p className="description-mobile">
            8 sociétaires sur 10 <br/>
            nous recommandent (vraiment).  <br/>
            Découvrez pourquoi !
          </p>
          <Button
              className="interview-btn"
              text="Voir les interviews"
              to="/clients/"
          />
        </div>
      </div>
      <div className="right-section">
        <BackgroundImage
            Tag="section"
            fluid={etreSocietaireImg}
          className="societaire-background-image"
        />
        <blockquote>
          <p>
            Vous connaissez toutes les problématiques <br />
            inhérentes au bâtiment, c’est pour cela que <br />
            l'on est fidèle à L'Auxiliaire BTP [...] c'est un <br />
            assureur sur lequel on peut compter.
          </p>
          <footer>Gilles C.</footer>
        </blockquote>
      </div>
    </div>
  );
}

EtreSocietaire.propTypes = {
  etreSocietaireImg: PropTypes.object.isRequired,
};
