import React from 'react';
import './headerHome.scss';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from "prop-types";

export default function HeaderHome({ headerBackground }) {
  return (
    <header className="header-home" id="header">
      <div className="header-home-background">
        <div className="bg-left">
          <BackgroundImage
            Tag="section"
            fluid={headerBackground}
            preserveStackingContext
            className="header-home-img desktop-bg"
          />
        </div>
        <div className="bg-right">
          <h2 className="desktop-text">
            Nous sommes L’Auxiliaire, <br />
            le partenaire assureur des <br />
            professionnels du BTP
          </h2>
          <p className="desktop-text">
            Parler le même langage, c’est l’assurance de se <br />
            comprendre. Depuis notre création, notre vocation est <br />
            d’apporter sérénité et sécurité aux acteurs de la <br />
            construction.
          </p>
          <h2 className="mobile-text">
            Nous sommes L’Auxiliaire, <br />
            le partenaire assureur des <br />
            professionnels du BTP
          </h2>
          <p className="mobile-text">
            Parler le même langage, c'est <br />
            l'assurance de se comprendre.<br />
          </p>
        </div>
      </div>
    </header>
  );
}

HeaderHome.propTypes = {
  headerBackground: PropTypes.object.isRequired,
  mobileHeaderBackground: PropTypes.object.isRequired,
};
