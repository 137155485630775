import React from "react";
import Button from "../../button/button";

import {getImage} from "gatsby-plugin-image";

import {BgImage} from "gbimage-bridge";

import {Link} from "gatsby";

import "./articleCard.scss";

export default function ArticleCard({
                                        className,
                                        img,
                                        title,
                                        date,
                                        size,
                                        slug,
                                        type,
                                        btnText = "Lire l’article",
                                        showDate = true
                                    }) {
    const image = getImage(img);

    function getClassName() {
        if(size) {
            return `articleCard--${size}`;
        }
        return '';
    }
    return (
            <BgImage image={image}>
              <Link
                to={`/${type}/${slug}`}
                className={`articleCard ${getClassName()} ${
                  className || ""
                }`}
              >
                {showDate && <div className="articleCard__date-wrapper">
                  <time className="articleCard__date">{date}</time>
                </div>}
                <h2 className="articleCard__heading">{title}</h2>
                <div className="articleCard__button-wrapper">
                  <Button text={btnText} className="articleCard__button" />
                </div>
              </Link>
            </BgImage>
    );
}
