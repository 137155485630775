import React from "react";

import "./fourBoxes.scss";
import BackgroundImage from "gatsby-background-image";

export default function FourBoxes({ boxImage1, boxImage2, boxImage3, boxImage4 }) {
  return (
    <div className="fourBoxes">
      <div className="fourBoxes-content">
        <div className="box">
          <a href="/notre-plateforme-innovation-et-services">
            <BackgroundImage
              Tag="section"
              fluid={boxImage1}
              preserveStackingContext
              className="background-image-fixed-size"
            />
            <div>
              <h2>Nos services</h2>
              <p>Engagés dans vos défis du quotidien et de demain. </p>
              <p>
                La plateforme prospective de L’Auxiliaire dédiée à ses sociétaires et au monde du BTP, au service
                d’une filière plus sûre et plus vertueuse.
              </p>
            </div>
            </a>
        </div>
        <div className="box">
          <a href="/chiffres-cles">
            <BackgroundImage
              Tag="section"
              fluid={boxImage2}
              preserveStackingContext
              className="background-image-fixed-size"
            />
            <div>
              <h2>Chiffres clés</h2>
              <p>Retrouvez l’ensemble de nos chiffres clés, nos statuts et rapports d’activités actualisés.</p>
              <p>
                Confier ses risques à L’Auxiliaire, c’est être certain de trouver à ses côtés un partenaire
                historique de votre secteur d’activité qui maîtrise parfaitement la gestion des risques longs et
                complexes.
              </p>
            </div>
          </a>
        </div>
        <div className="box">
          <a href="/partenaires">
            <BackgroundImage
              Tag="section"
              fluid={boxImage3}
              preserveStackingContext
              className="background-image-fixed-size"
            />
            <div>
              <h2>Partenaires</h2>
              <p>
                S'engager à accompagner tous les acteurs de la construction dans la réalisation de
                leurs projets
              </p>
              <p>
                Dans le secteur du BTP, tisser des liens solides avec ses partenaires revêt
                une importance cruciale pour l'avenir et la sécurité des hommes
              </p>
            </div>
          </a>
        </div>
        <div className="box">
          <a href="/nous-rejoindre">
            <BackgroundImage
              Tag="section"
              fluid={boxImage4}
              preserveStackingContext
              className="background-image-fixed-size"
            />
            <div>
              <h2>Nous rejoindre</h2>
              <p>Découvrez les valeurs que nous partageons avec nos collaborateurs</p>
              <p>
                Notre Direction des Ressources Humaines est à l’écoute des enjeux et des mutations de
                son environnement socio-économique et répond par des politiques adaptées.
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
