import React from "react";
import ArticleCard from "../card/articleCard/articleCard";

import "./lastArticles.scss";
import Button from "../button/button";

import defaultPhoto from "../../images/articles/photo-article-default.webp";

import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
SwiperCore.use([Navigation]);


export default function LastArticles({ articles }) {
  return (
    <>
      <section className="article__list_section">
        <div className="article__list_page">
          {articles.slice(0, 4).map((article, i) => (
            <div key={i}>
              <ArticleCard
                size="small"
                title={article.Titre}
                date={article.Date_De_Publication}
                slug={article.Slug}
                type="article"
                img={
                  article.Image_Vignette
                    ? article.Image_Vignette.localFile
                    : defaultPhoto
                }
              />
            </div>
          ))}
        </div>
        <div className="button-wrapper">
          <Button
            className="last-articles__btn"
            text="Plus d'articles"
            to="/articles/"
          />
        </div>
      </section>
      <section className="article__mobile-swiper">
        <Swiper
          slidesPerView={2}
          roundLengths={true}
          navigation={true}
          loop={true}
        >
          {articles.slice(0, 4).map((article, i) => (
            <SwiperSlide key={i}>
              <div className="article__mobile__tuile">
                <ArticleCard
                  size="small"
                  title={article.Titre}
                  date={article.Date_De_Publication}
                  slug={article.Slug}
                  type="article"
                  img={
                    article.Image_Vignette
                      ? article.Image_Vignette.localFile
                      : defaultPhoto
                  }
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="button-wrapper">
          <Button
            className="last-articles__btn"
            text="Plus d'articles"
            to="/articles/"
          />
        </div>
      </section>
      <section className="article__tablette-swiper">
        <Swiper
          slidesPerView={3}
          centeredSlides={true}
          spaceBetween={20}
          roundLengths={true}
          navigation={true}
          loop={true}
        >
          {articles.slice(0, 4).map((article, i) => (
            <SwiperSlide key={i} className={i < 3 ? "custom-class" : ""}>
              <div className="article__tablette__tuile">
                <ArticleCard
                  size="small"
                  title={article.Titre}
                  date={article.Date_De_Publication}
                  slug={article.Slug}
                  type="article"
                  img={
                    article.Image_Vignette
                      ? article.Image_Vignette.localFile
                      : defaultPhoto
                  }
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="button-wrapper">
          <Button
            className="last-articles__btn"
            text="Plus d'articles"
            to="/articles/"
          />
        </div>
      </section>
    </>
  );
}
