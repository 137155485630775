import React from 'react';
import PropTypes from 'prop-types';
import './support.scss';
import BackgroundImage from 'gatsby-background-image';
import Button from '../button/button';

export default function Support({ nosValeurs1, nosValeurs2 }) {
  return (
      <>
        <div className="support">
          <div className="content-wrapper">
            <div className="text-section">
              <p className="subtitle">
                <strong>
                  En tant qu’assureur spécialiste des métiers du
                  BTP, nous accompagnons tous les acteurs de la
                  construction.
                </strong>
              </p>
              <p>
                A l’écoute de leurs besoins, attentif aux évolutions
                des marchés et de la réglementation, nous leur
                proposons les meilleures solutions pour la
                protection de leurs responsabilités, de leurs biens
                et des personnes qui les entourent.
              </p>
              <p>
                Notre statut mutualiste nous permet de tisser des
                liens durables avec nos clients-sociétaires et nos
                partenaires, basés sur une confiance réciproque.
              </p>
              <Button
                  className="nos-valeurs-btn"
                  text="Nos valeurs"
                  to="/nos-valeurs"
              />
            </div>
            <BackgroundImage
                Tag="section"
                fluid={nosValeurs1}
                preserveStackingContext
                className="support-img1"
            />
            <BackgroundImage
                Tag="section"
                fluid={nosValeurs2}
                preserveStackingContext
                className="support-img2"
            />
          </div>
        </div>
        <div className="support-mobile">
          <div className="images-container">
            <BackgroundImage
                Tag="section"
                fluid={nosValeurs1}
                preserveStackingContext
                className="support-img1"
            />
            <BackgroundImage
                Tag="section"
                fluid={nosValeurs2}
                preserveStackingContext
                className="support-img2"
            />
          </div>
          <div className="content-wrapper">
            <div className="text-section">
              <p className="subtitle">
                <strong>
                  En tant qu’assureur spécialiste des métiers du
                  BTP, nous accompagnons tous les acteurs de la
                  construction.
                </strong>
              </p>
              <p>
              A l’écoute de leurs besoins, attentif aux évolutions
                des marchés et de la réglementation, nous leur
                proposons les meilleures solutions pour la
                protection de leurs responsabilités, de leurs biens
                et des personnes qui les entourent.
              </p>
              <p>
                Notre statut mutualiste nous permet de tisser des
                liens durables avec nos clients-sociétaires et nos
                partenaires, basés sur une confiance réciproque.
              </p>
            </div>
          </div>
        </div>

      </>
  )
      ;
}

Support.propTypes = {
  nosValeurs1: PropTypes.object.isRequired,
  nosValeurs2: PropTypes.object.isRequired,
};
