import React from "react";

import "./threeBoxesMobile.scss";
import BackgroundImage from "gatsby-background-image";
import { Swiper, SwiperSlide } from "swiper/react";

export default function ThreeBoxesMobile({ boxImage1, boxImage2, boxImage3, boxImage4 }) {
  return (
    <section className="fourBoxes-mobile">
      <Swiper
        slidesPerView={3}
        spaceBetween={1}
        centeredSlides={true}
        initialSlide={1}
        loop={true}
      >
        <SwiperSlide>
          <BackgroundImage
            Tag="section"
            fluid={boxImage1}
            preserveStackingContext
          >
            <a href="/nos-valeurs">Nos valeurs</a>
          </BackgroundImage>
        </SwiperSlide>
        <SwiperSlide>
          <BackgroundImage
            Tag="section"
            fluid={boxImage2}
            preserveStackingContext
          >
            <a href="/nos-engagements-environnementaux">Préserver demain</a>
          </BackgroundImage>
        </SwiperSlide>
        <SwiperSlide>
          <BackgroundImage
            Tag="section"
            fluid={boxImage4}
            preserveStackingContext
          >
            <a href="/offres-emploi">Nous rejoindre</a>
          </BackgroundImage>
        </SwiperSlide>
        <SwiperSlide>
          <BackgroundImage
            Tag="section"
            fluid={boxImage3}
            preserveStackingContext
          >
            <a href="/partenaires">Partenaires</a>
          </BackgroundImage>
        </SwiperSlide>
      </Swiper>
    </section>
  );
}
