import React from "react";
import PersonCard from "../card/personCard/personCard";

import "./courtageInterlocuteursGrid.scss";

export default function CourtageInterlocuteursGrid({ personList }) {
  const maxPersons = 10;

  const limitedList = personList.slice(0, maxPersons);

  const hasMoreThanFive = personList.length > 5 && maxPersons > 5;

  let firstRow = limitedList;
  let secondRow = [];

  if (hasMoreThanFive) {
    const half = Math.ceil(limitedList.length / 2);
    firstRow = limitedList.slice(0, half);
    secondRow = limitedList.slice(half);
  }

  return (
    <section className={`courtage-interlocuteurs-grid ${hasMoreThanFive ? 'more-than-five' : 'five-or-less'}`}>
      <div className="courtage-interlocuteurs-grid__row">
        {firstRow.map((person, i) => (
          <PersonCard
            key={i}
            person={person}
            className="courtage-interlocuteurs-grid__person-card"
          />
        ))}
      </div>
      {hasMoreThanFive && secondRow.length > 0 && (
        <div className="courtage-interlocuteurs-grid__row">
          {secondRow.map((person, i) => (
            <PersonCard
              key={i + firstRow.length}
              person={person}
              className="courtage-interlocuteurs-grid__person-card"
            />
          ))}
        </div>
      )}
      <div className="courtage-interlocuteurs-grid__row-mobile">
        {limitedList.map((person, i) => (
          <PersonCard
            key={i}
            person={person}
            className="courtage-interlocuteurs-grid__person-card-mobile"
          />
        ))}
      </div>
    </section>
  );
}
