import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import HeaderImage from "../../components/header/headerImage/headerImage";
import CitationCard from "../../components/card/citationCard/citationCard";
import ClientCarousel from "../../components/carousel/interviewCarousel/interviewCarousel";

import "./style.scss";

import header from "../../images/clients/header.webp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft, faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";

const Clients = ({location, data}) => {
    const interviewsNode = data.allStrapiInterviewClient.nodes[0].Interviews;

    const formatInterviews = (value) => {

        // On map dans un objet propre pour pouvoir filtrer le haut et le bas
        return value.map((interview) => {
            return {
                Citation: {
                    data: interview.Citation.data,
                },
                Nom: interview.Nom,
                Position_carousel: interview.Position_carousel,
                Presentation: {
                    data: interview.Presentation.data,
                },
                VideoId: interview.VideoId,
            };
        });
    };
    const interviews = formatInterviews(interviewsNode)

    const interviewsTop = interviews.filter(i => !i.Position_carousel || i.Position_carousel === 'Haut');
    const interviewsBot = interviews.filter(i => i.Position_carousel && i.Position_carousel === 'Bas');

    const clients = data.allStrapiInterviewClient.nodes[0].Clients;
    return (
        <>
            <Helmet>
                <body className="clients"/>
            </Helmet>
            <Layout>
                <SEO title="Paroles de sociétaires"
                     description="Entrepreneurs, artisan ou professionnels des travaux publics, découvrez les témoignages de nos sociétaires et leur expérience avec nos experts en assurance du BTP."/>
                <HeaderImage
                    title="Paroles de sociétaires"
                    text="Quand proximité et accompagnement deviennent plus que des mots. L'Auxiliaire entretient avec ses sociétaires des relations de grande proximité basées sur la confiance, l'empathie et le partage de connaissances techniques liées à la construction&nbsp;!"
                    imagePath={header}
                    crumbLabel="Être sociétaire de L'Auxiliaire, c'est comment&nbsp;?"
                    location={location}
                />

                {interviewsTop?.length > 0 && (
                    <ClientCarousel interviews={interviewsTop}/>
                )}

                <section className="clients__big-citation">

                    <FontAwesomeIcon
                        className="clients__big-citation__left-quote"
                        icon={faAngleDoubleLeft}
                    />
                    <p>Parce que confiance mutuelle et proximité sont pour nous l'assurance de projets bien menés, nous
                        oeuvrons au quotidien à maintenir le lien avec nos sociétaires.</p>
                    <FontAwesomeIcon
                        className="clients__big-citation__right-quote"
                        icon={faAngleDoubleRight}
                    />
                </section>
                <section className="clients__citations">
                    {clients.map((client, i) => {
                        return <CitationCard key={i} client={client}/>;
                    })}
                </section>

                {interviewsBot?.length > 0 && (
                    <ClientCarousel interviews={interviewsBot} noBackground={true}/>
                )}

            </Layout>
        </>
    );
};

export const query = graphql`
  query ClientsPageQuery {
    allStrapiInterviewClient {
      nodes {
        Interviews {
          Citation {
            data
          }
          Nom
          Position_carousel
          Presentation {
            data
          }
          VideoId
        }

        Clients {
          Nom
          Citation
          Photo {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80)
              }
            }
          }
        }
      }
    }
  }
`;

export default Clients;
