import React from "react";
import JobLineItem from "./jobLineItem/jobLineItem";
import Button from "../button/button";

import "./jobLine.scss";

import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
SwiperCore.use([Navigation]);

export default function JobLine({ metiers }) {
  return (
    <>
      <div className="jobLine">
        <div className="jobLine__tuiles">
          {metiers.map((metier, i) => (
            <JobLineItem
              key={i}
              title={metier.Titre}
              img={metier.Image_De_Couverture.localFile}
              slug={metier.produit_slug}
            />
          ))}
        </div>
        <div className="jobLine__buttons">
          <Button to="/assurances" text="Nos assurances" className="fixed-size center-text-btn" />
          <Button to="/mon-agence" text="Nos agences" className="fixed-size center-text-btn" />
          <Button to="/espace-courtage" text="Service courtage" className="btn-secondary-color fixed-size center-text-btn" />
        </div>
      </div>
      <div className="jobLine-mobile">
      <Swiper
          slidesPerView={2}
          roundLengths={true}
          navigation={true}
          loop={true}
        >
          {metiers.map((metier, i) => (
            <SwiperSlide key={i}>
              <div className="jobLine-mobile__tuile">
                <JobLineItem
                  key={i}
                  title={metier.Titre}
                  img={metier.Image_De_Couverture.localFile}
                  slug={metier.produit_slug}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
